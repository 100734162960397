import Vue from 'vue/dist/vue.esm.js'
import axios from 'axios'

document.addEventListener("DOMContentLoaded", () => {

var token = document.getElementById("authenticity_token").value
const app = new Vue({
  el: '#signup-form',

  // our data
  data: {
    name: '',
    email: '',
    subject: '',
    content: '',
    message: '',
    send: false,
    spinner: false
    
  },

  // our methods
  methods: {
    processForm: function() {
      console.log({ name: this.name, email: this.email, token: token });

  this.message = '<h4>Wysyłanie wiadomości</h4>';
  this.spinner =  true;
  axios.post('send-mail',{
    contact: {name: this.name, 
              email: this.email,
              subject: this.subject,
              content: this.content 
              },
              authenticity_token: token




    } )
    .then((response)=> {

      this.message = "<h4>wiadomość została wysłana</h4>"
      this.send = true
      this.spinner = false
     
    })
    .catch((error)=> {
      this.message = "<h4>nie udało się wysłać wiadomości</h4>"
      this.spinner = false
    })
    .then(()=> {
     name: ''

    });

    },
  }
});






  // here is the Vue code






});